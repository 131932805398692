import * as React from "react";
import { privacyContainer, privacyHeader } from "./Privacy.module.scss";

export const PrivacyContainer: React.FC<{ children: React.ReactNode }> = (
  props
) => {
  return <div className={privacyContainer}>{props.children}</div>;
};

export const PrivacyHeader: React.FC<{ children: React.ReactNode }> = (
  props
) => {
  return <h1 className={`text-center ${privacyHeader}`}>{props.children}</h1>;
};
